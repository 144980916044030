import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ImageCarousel from "../components/img/ImageCarousel"
import LogoStream from "../components/img/LogoStream"
import { StaticImage } from "gatsby-plugin-image"
import PubSearch from "../components/PubMap/Search"

const GiftCardsPage = () => {
  const images = [
    {
      src: "/banners/landing-banner-giftcards-1.jpg",
      alt: "Cocktails",
      link: null,
    },
    {
      src: "/banners/landing-banner-giftcards-2.jpg",
      alt: "Steak",
      link: null,
    },
    {
      src: "/banners/landing-banner-giftcards-3.jpg",
      alt: "Desserts",
      link: null,
    },
    {
      src: "/banners/landing-banner-giftcards-4.jpg",
      alt: "Pies",
      link: null,
    },
    {
      src: "/banners/landing-banner-giftcards-5.jpg",
      alt: "Seafood",
      link: null,
    },
  ]

  const cardVariations = [20, 30, 40, 50, 75, 100]

  return (
    <Layout>
      <Seo
        title={`Restaurant, Pub & Bar Gift Cards | The Dining Out Gift Card`}
        description={`Reloadable restaurant Gift Cards. Treat your family and friends to experience a meal or drinks out. Fast delivery by post & email. Use at 1600+ venues.`}
      />

      <ImageCarousel images={images} />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main">
          <h1>Dining Out gift cards</h1>

          <p>
            Eating out is one of life's simple pleasures, and it's even more
            enjoyable with such a great selection of quality restaurants, bars
            and pubs to choose from. That's why The Dining Out Gift Card makes
            the perfect present, whatever the occasion. From a heartfelt thank
            you, to a joyous congratulations or well done, the Dining Out Gift
            Card is a great way to reward a friend, family member or colleague.
          </p>

          <p>
            Quick and convenient to buy online, our gift cards can be used at
            any of our locations. From stylish city favourites like Browns,
            Miller & Carter and All Bar One, to characterful hidden gems just
            waiting to be discovered, plus a whole host of family–friendly
            brands like Harvester and Toby Carvery.
          </p>

          <p>
            <strong>Choose the perfect greeting card:</strong>
          </p>

          <StaticImage
            src="../../static/general/greeting-cards-on-background.png"
            alt="Choose the perfect greeting card"
            className="w-full mb-3"
          />

          <p>
            <strong>Choose the perfect e-mail template:</strong>
          </p>

          <StaticImage
            src="../../static/general/occasions-grid.jpg"
            alt="Choose the perfect e-mail template"
            className="w-full mb-3"
          />

          <div className="flex flex-wrap mt-4 md:mt-10 justify-center">
            <div className="w-full md:w-5/12 px-2 mt-2 md:mt-0">
              <a
                href="https://diningout.cashstar.com/"
                rel="noreferrer"
                target="_blank"
                className="w-full text-white uppercase bg-gold hover:bg-gold-dark block px-4 py-2 flex justify-between"
              >
                <span>Buy a Gift Card</span>
                <span>&gt;&gt;</span>
              </a>
            </div>
            <div className="w-full md:w-5/12 px-2 mt-2 md:mt-0">
              <a
                href="https://www.showmybalance.com/"
                rel="noreferrer"
                target="_blank"
                className="w-full text-white uppercase bg-gold hover:bg-gold-dark block px-4 py-2 flex justify-between"
              >
                <span>Check your balance</span>
                <span>&gt;&gt;</span>
              </a>
            </div>
          </div>

          <div className="flex flex-wrap mt-4 w-full lg:max-w-[83.333%] mx-auto">
            {cardVariations.map((cardValue, index) => {
              return (
                <div
                  key={index}
                  className="w-full md:w-1/2 lg:w-1/3 text-center"
                >
                  <div className="bg-blue-dark m-2 py-8 px-4 rounded-lg">
                    <div
                      style={{ height: "2px" }}
                      className="w-full bg-gold mb-4"
                    ></div>
                    <h5 className="text-4xl">£{cardValue}</h5>
                    <p className="uppercase text-center text-sm mb-5">
                      Gift Card
                    </p>
                    <a
                      href={`https://diningout.cashstar.com/#VOUCH${cardValue}`}
                      rel="noreferrer"
                      target="_blank"
                      className="mx-auto text-white uppercase bg-gold hover:bg-gold-dark px-4 py-2"
                    >
                      Buy Now
                    </a>
                    <div
                      style={{ height: "2px" }}
                      className="w-full bg-gold mt-8"
                    ></div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="flex flex-wrap mt-4 justify-center">
            <div className="w-full md:w-5/12 px-2 mt-2 md:mt-0">
              <a
                href="https://diningout.cashstar.com/"
                rel="noreferrer"
                target="_blank"
                className="w-full"
              >
                <StaticImage
                  src="../../static/boxes/buy-now-2.png"
                  alt="Dining Out Card"
                  className="w-full"
                />
              </a>
            </div>
            <div className="w-full md:w-5/12 px-2 mt-2 md:mt-0">
              <a
                href="https://diningout-biz.cashstar.com/"
                rel="noreferrer"
                target="_blank"
                className="w-full"
              >
                <StaticImage
                  src="../../static/boxes/corporate-gift-cards.png"
                  alt="Corporate Gifts"
                  className="w-full"
                />
              </a>
            </div>            
          </div>
        </div>
      </div>

      <PubSearch />
    </Layout>
  )
}

export default GiftCardsPage
