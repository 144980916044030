import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"
import "slick-carousel/slick/slick.css"
// import "extraStyles.css"

function ImageCarousel({ images }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => {
          if (image.link !== null) {
            // Check if this is an internal link
            if (image.link.startsWith("/")) {
              return (
                <div key={index}>
                  <Link href={image.link} rel="noreferrer">
                    <img src={image.src} alt={image.alt} className="w-full" />
                  </Link>
                </div>
              )
            } else {
              return (
                <div key={index}>
                  <a href={image.link} rel="noreferrer">
                    <img src={image.src} alt={image.alt} className="w-full" />
                  </a>
                </div>
              )
            }
          } else {
            return (
              <div key={index}>
                <img src={image.src} alt={image.alt} className="w-full" />
              </div>
            )
          }
        })}
      </Slider>
      <div className={`slick-slider !hidden`}>
        <ul className="slick-dots">
          <li className="slick-active">
            <button></button> {/* eslint-disable-line */}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ImageCarousel
